import { Media } from 'media'
import { ComponentType } from 'react'
import { SeoFooterDesktop } from './SeoFooterDesktop'
import { SeoFooterMobile } from './SeoFooterMobile'

interface Props {
  compact?: boolean
}

export const SeoFooter: ComponentType<Props> = ({ compact = false }) => (
  <>
    <Media at="xs">
      <SeoFooterMobile compact={compact} />
    </Media>
    <Media greaterThan="xs">
      <SeoFooterDesktop compact={compact} />
    </Media>
  </>
)
