import { CoverBackground } from 'components/CoverBackground'
import Link from 'next/link'
import { ComponentType } from 'react'
import { FormattedDate } from 'react-intl'

export const BlogPostCard: ComponentType<{ post: any }> = ({ post }) => {
  return (
    <div>
      <CoverBackground url={post.feature_image} className="h-280px mb-15 rounded-md-top" />
      {post.primary_tag && (
        <h6 className="mb-15 font-size-14 text-uppercase text-primary font-weight-bold">{post.primary_tag.name}</h6>
      )}
      <Link
        href={{
          pathname: '/blog/[postSlug]',
          query: { postSlug: post.slug },
        }}
        as={`/blog/${post.slug}`}
      >
        <a
          title={post.title}
          className="d-block mb-10 font-size-20 font-family-serif font-weight-bold line-height-md text-body"
        >
          {post.title}
        </a>
      </Link>
      <p className="mb-20 line-height-md">{post.excerpt}</p>
      <h6 className="mb-15 font-size-14 text-uppercase text-raven font-weight-bold">
        <FormattedDate value={post.created_at} day="numeric" month="long" year="numeric" />
      </h6>
    </div>
  )
}
