import { DEFAULT_ZONE_SLUG, RENT_SLUG, SALE_SLUG } from 'consts'
import { useCategoriesQuery } from 'hooks/useCategoriesQuery'
import Link from 'next/link'
import { ComponentType } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Container, Row } from 'reactstrap'

interface Props {
  compact: boolean
}

export const SeoFooterDesktop: ComponentType<Props> = ({ compact }) => {
  const { data } = useCategoriesQuery()
  const { formatMessage } = useIntl()
  const categories = data?.categories.data ?? []

  return (
    <Container>
      <Row>
        <Col>
          {compact ? (
            <>
              <h3 className="mb-40 font-size-22 font-family-serif font-weight-bold">
                <FormattedMessage id="seofooter.title.compact" />
              </h3>
            </>
          ) : (
            <div className="text-center">
              <h3 className="font-size-26 font-family-serif font-weight-bold">
                <FormattedMessage id="seofooter.title" />
              </h3>
              <img alt="" src="/images/building-illustration.svg" className="mt-24 mb-90 img-fluid" />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <h6 className="mb-15 font-size-17 font-weight-600">
            <FormattedMessage id="seofooter.sale.title" />
          </h6>
          <Row>
            {categories.map((category: any) => (
              <Col key={category.uuid} lg={6} className="py-4px">
                <Link
                  href="/[categorySlug]/[roleSlug]/[zoneSlug]"
                  as={`/${category.slug}/${SALE_SLUG}/${DEFAULT_ZONE_SLUG}`}
                >
                  <a
                    title={formatMessage(
                      {
                        id: 'seofooter.sale.link',
                      },
                      { name: category.pluralName },
                    )}
                    className="inversed-link-raven"
                  >
                    <FormattedMessage id="seofooter.sale.link" values={{ name: category.pluralName }} />
                  </a>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={6}>
          <h6 className="mb-15 font-size-17 font-weight-600">
            <FormattedMessage id="seofooter.rent.title" />
          </h6>
          <Row>
            {categories.map((category: any) => (
              <Col key={category.uuid} lg={6} className="py-4px">
                <Link
                  href="/[categorySlug]/[roleSlug]/[zoneSlug]"
                  as={`/${category.slug}/${RENT_SLUG}/${DEFAULT_ZONE_SLUG}`}
                >
                  <a
                    title={formatMessage(
                      {
                        id: 'seofooter.rent.link',
                      },
                      { name: category.pluralName },
                    )}
                    className="inversed-link-raven-success"
                  >
                    <FormattedMessage id="seofooter.rent.link" values={{ name: category.pluralName }} />
                  </a>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
