import cx from 'classnames'
import { DEFAULT_ZONE_SLUG, RENT_SLUG, SALE_SLUG } from 'consts'
import { useCategoriesQuery } from 'hooks/useCategoriesQuery'
import Link from 'next/link'
import { ComponentType, Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Container, Row } from 'reactstrap'

interface Props {
  compact: boolean
}

export const SeoFooterMobile: ComponentType<Props> = ({ compact }) => {
  const { data } = useCategoriesQuery()
  const { formatMessage } = useIntl()
  const categories = data?.categories.data ?? []

  return (
    <Container>
      <Row>
        <Col>
          {compact ? (
            <h3 className="mb-0 font-size-20 font-family-serif font-weight-bold">
              <FormattedMessage id="seofooter.title.compact" />
            </h3>
          ) : (
            <div className="text-center">
              <h3 className="mb-0 px-50 font-size-21 font-weight-bold">
                <FormattedMessage id="seofooter.title" />
              </h3>
              <div className="pt-40">
                <img alt="" src="/images/building-illustration.svg" className="img-fluid" />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row
        className={cx({
          'pt-55': !compact,
          'pt-40': compact,
        })}
      >
        <Col>
          <h6 className="mb-10 font-weight-600">
            <FormattedMessage id="seofooter.sale.title" />
          </h6>
          {categories.map((category: any, index: number) => (
            <Fragment key={category.uuid}>
              {index !== 0 && <span className="px-10">·</span>}
              <Link
                href="/[categorySlug]/[roleSlug]/[zoneSlug]"
                as={`/${category.slug}/${SALE_SLUG}/${DEFAULT_ZONE_SLUG}`}
              >
                <a
                  title={formatMessage(
                    {
                      id: 'seofooter.sale.link',
                    },
                    { name: category.pluralName },
                  )}
                  className="font-size-15 line-height-lg inversed-link-raven"
                >
                  <FormattedMessage id="seofooter.sale.link" values={{ name: category.pluralName }} />
                </a>
              </Link>
            </Fragment>
          ))}
        </Col>
      </Row>
      <Row className="pt-40 pb-24">
        <Col>
          <h6 className="mb-10 font-weight-600">
            <FormattedMessage id="seofooter.rent.title" />
          </h6>
          {categories.map((category: any, index: number) => (
            <Fragment key={category.uuid}>
              {index !== 0 && <span className="px-10">·</span>}
              <Link
                href="/[categorySlug]/[roleSlug]/[zoneSlug]"
                as={`/${category.slug}/${RENT_SLUG}/${DEFAULT_ZONE_SLUG}`}
              >
                <a
                  title={formatMessage(
                    {
                      id: 'seofooter.rent.link',
                    },
                    { name: category.pluralName },
                  )}
                  className="font-size-15 line-height-lg inversed-link-raven-success"
                >
                  <FormattedMessage id="seofooter.rent.link" values={{ name: category.pluralName }} />
                </a>
              </Link>
            </Fragment>
          ))}
        </Col>
      </Row>
    </Container>
  )
}
